<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline'
import type { PropType } from 'vue'
import type { Iitems } from '@/modules/shared/interfaces/inputMultiple'

import useInputMultiple from '@/modules/shared/composable/useInputMultiple'

interface Emits {
  (e: 'update:text', values: string): void;
  (e: 'update:items', values: Iitems[]): void;
}

const emits = defineEmits<Emits>()

const props = defineProps({
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  placeholder: {
    type: String,
    required: false,
    default: 'Type in or paste a list of mailboxes'
  },
  text: {
    type: String,
    required: true
  },
  maxHeight: {
    type: String,
    required: false,
    default: 'max-h-20'
  },
  items: {
    type: Array as PropType<Iitems[]>,
    required: true
  },
  reg: {
    type: Function as PropType<(e:string) => boolean>,
    required: true
  },
  allowedDomains: {
    type: Array as PropType<string[]>,
    required: true
  },
  onlyExistEmails: {
    type: Array as PropType<string[]>,
    required: true
  },
  space: {
    type: Boolean,
    required: false,
    default: false
  }
})

const { inputAddEmail, placeholderF, insertItem, DeleteLastEmail, ChangeEmail, DeleteEmail, closeDropdown } = useInputMultiple(props, emits)
const space = () => {
  if (!props.space) insertItem()
}
</script>

<template>
  <div v-click-outside="closeDropdown" class="relative box-border ring-1 ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-500 rounded-md min-h-[38px] overflow-hidden">
    <div
      class="bg-white shadow-xs py-2 pl-[13px] pr-3 min-h-[38px] overflow-y-auto !border-none rounded-md appearance-none flex whitespace-normal"
      :class="[{ '!bg-gray-50 ': disabled }, maxHeight]"
    >
      <div v-if="!disabled" @click="() => inputAddEmail.focus()" class="text-sm flex basis-full grow flex-wrap py-0 relative gap-2 items-start content-start">
        <span
          v-for="(e, i) in items"
          :key="i"
          class="flex items-center border border-gray-300 pr-1 pt-[1px] pb-[1px] pl-2.5 font-medium text-xs leading-[18px] text-center bg-transparent text-gray-800 rounded"
          :class="{ 'error border-red-400 text-red-400': !e.status, 'pr-2.5': e.canNotDelete }"
        >
        {{ e.item }}
          <div v-if="!e.canNotDelete" class="ml-1 rounded-full hover:bg-gray-200 w-4 h-4 group flex items-center justify-center cursor-pointer">
            <XMarkIcon class="w-3 h-3 text-gray-400 group-hover:text-gray-500 inline-block" @click="DeleteEmail(e.item)" />
          </div>
        </span>
        <input
          id="inputAddEmail"
          ref="inputAddEmail"
          type="text"
          class="placeholder-gray-500 p-0 min-w-[10px] text-sm leading-5.25 m-0 text-gray-700 !border-none appearance-none outline-none bg-none shadow-none w-0 max-w-full grow z-10 focus:ring-0 focus:ring-offset-0"
          :value="text"
          :placeholder="placeholderF()"
          @keyup.enter="insertItem"
          @keyup.enter.space="space"
          @keyup.,="space"
          @keydown.tab.prevent="insertItem"
          @keyup.delete="DeleteLastEmail"
          @input="ChangeEmail"
        />
      </div>
      <input
        v-else
        class="p-0 min-w-1/2 text-sm leading-5 m-0 !border-none appearance-none outline-none bg-none shadow-none w-0 max-w-full grow z-10 focus:ring-0 focus:ring-offset-0 !bg-gray-50 !text-gray-500 !cursor-not-allowed"
        type="text"
        disabled
        :value="items[0].item"
      />
    </div>
  </div>
</template>

<style scoped></style>

<script setup lang="ts">
import { UsersIcon } from '@heroicons/vue/24/outline'
import ButtonG from '@/modules/shared/components/ButtonComponent.vue'
import useModal from '@/modules/shared/composable/useModal'

const { showModal } = useModal()

defineProps({
  title: {
    type: String,
    required: true
  },
  text: {
    type: String,
    required: true
  },
  textButton: {
    type: String,
    required: true
  },
  modalName: {
    type: String,
    required: true
  }
})

</script>

<template>
  <!-- No hay datos guardados -->
  <tbody class="border !border-b">
    <tr>
      <td class="bg-white" colspan="5">
        <div class="p-10 flex flex-col justify-center items-center">
          <div class="p-3.5 bg-indigo-100 rounded-full">
            <slot name="icon">
              <UsersIcon class="w-5 h-5 text-indigo-500" />
            </slot>
          </div>
          <p class="text-gray-900 text-base leading-6 font-medium pt-2">{{ title }}</p>
          <p class="text-gray-500 text-sm leading-5 w-1/4 m-auto text-center pt-1" v-html="text"></p>
          <div class="w-1/3 pt-6 flex justify-center">
            <ButtonG
              @click="showModal({ name: modalName })"
              :text=textButton
              class="ml-2 !w-49"
            />
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<style scoped>

</style>

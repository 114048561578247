<script setup lang="ts">
import type { PropType } from 'vue'
import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid'

import useSelect from './useSelectSimple'
import type { IData } from './SelectSimple'

interface Emits {
  (e: 'update:values', values: IData): void;
  (e: 'update:valueSimple', values: string): void;
}

const emits = defineEmits<Emits>()

const props = defineProps({
  options: {
    type: Array as PropType<IData[]>,
    required: false,
    default: () => [{ title: 'None', description: '' }]
  },
  values: {
    type: Object as PropType<IData>,
    required: false,
    default: () => ({ title: 'None', description: '' })
  },
  optionSimple: {
    type: Array as PropType<string[]>,
    required: false
  },
  valueSimple: {
    type: String,
    required: false
  },
  typeSelect: {
    type: String,
    required: false,
    default: 'option1'
  },
  finder: {
    type: Boolean,
    required: false,
    default: false
  },
  placeholder: {
    type: String,
    required: false
  },
  label: {
    type: String,
    required: false
  }
})

const {
  open,
  search,
  multiselectContent,
  boxMiltiselect,
  multiselectOptions,
  findId,

  optionsFilter,
  selected,
  selectedSimple,
  openSelect,
  closeDropdown
} = useSelect(props, emits)
</script>

<template>
  <div v-if="label" class="text-sm font-medium leading-5 text-gray-700 mb-1">{{ label }}</div>

  <div
    ref="boxMiltiselect"
    v-click-outside="closeDropdown"
    class="flex justify-between w-full text-sm py-0 relative bg-white shadow-xs !border-none rounded-md appearance-none whitespace-normal box-border ring-1 cursor-pointer ring-gray-300 focus-within:ring-2"
    :class="{'ring-2 ring-indigo-500': open && !finder}"
    @click="openSelect"
    >
    <div class="flex items-center py-2 pl-[13px] pr-3 grow min-h-[38px] max-h-20">
      <template v-if="values.title != 'None'">
        <span v-if="values.title === '' && placeholder" class="text-gray-500">{{ placeholder }}</span>
        <template v-else>
          <div v-if="typeSelect === 'option1'" class="text-gray-900" :class="{'!text-gray-500': open}">
            {{ values.title }}
          </div>
          <template v-if="typeSelect === 'list'">
            <span class="text-sm text-gray-900 leading-5 pr-1">{{ values.title }}</span>
            <!-- <span class="text-sm text-gray-500 leading-5">GMT {{ values.description }}</span> -->
          </template>
        </template>
      </template>
      <template v-else>
        <span class="text-sm text-gray-900 leading-5 pr-2">{{ valueSimple }}</span>
      </template>
    </div>
    <div class="pr-3 py-2 absolute right-0">
      <ChevronUpIcon v-show="open" class="w-5 h-5 text-gray-500 group-hover:text-gray-500 inline-block" />
      <ChevronDownIcon v-show="!open" class="w-5 h-5 text-gray-500 group-hover:text-gray-500 inline-block" />
    </div>
  </div>

  <Teleport to="body">
    <div v-show="open" ref="multiselectOptions" class="SelectSimpleOptions shadow-select absolute block z-50 rounded-md top-0 overflow-auto bg-white ring-0">

      <div v-show="finder" id="finder" class="relative mt-2 rounded-md shadow-sm min-h-[38px] mx-3 my-2">
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input id="searchSelectSimple" ref="findId" v-model="search" type="email" class="block w-full min-h-[38px] rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Search" />
      </div>

      <ul class="inline-block min-w-full align-top my-1 min-h-[30px] max-h-[200px] overflow-auto m-0 p-0 list-none" ref="multiselectContent">
      <template v-if="values.title != 'None'">
        <li
          v-for="(option, index) in optionsFilter()"
          :key="index"
          @click="selected(option)"
          class="block cursor-pointer hover:bg-gray-50 px-3 py-2"
        >
        <template v-if="typeSelect === 'option1'">
          <div class="text-sm text-gray-900 leading-5 mb-1">{{ option.title }}</div>
          <div class="text-sm text-gray-500 leading-5">{{ option.description }}</div>
        </template>
        <template v-else-if="typeSelect === 'list'">
          <span class="text-sm text-gray-900 leading-5 pr-2">{{ option.title }}</span>
          <!-- <span class="text-sm text-gray-500 leading-5">GMT {{ option.description }}</span> -->
        </template>
      </li>
    </template>
    <template v-else>
      <li
        v-for="(option, index) in optionSimple"
        :key="index"
        @click="selectedSimple(option)"
        class="text-sm text-gray-900 leading-5 px-3 py-2  block cursor-pointer hover:bg-gray-50"
      >
        {{ option }}
      </li>
    </template>
      </ul>
    </div>
  </Teleport>
</template>

<style scoped>
</style>

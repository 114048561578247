<script setup lang="ts">
import { computed } from 'vue'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline'
import help from '@/assets/icons/help.svg'
import useTable from '@/modules/settings/composables/useTable'
import useUsers from '@/modules/settings/composables/useUsers'
import useUserInfo from '@/modules/shared/composable/useUserInfo'

const props = defineProps({
  selectFiltered: {
    type: Object,
    required: true
  }
})
const { asc, typeOrder, selectedItems, selectAll, indeterminate, orderBy } = useTable()
const { users } = useUsers()
const { userInfo } = useUserInfo()

const all = computed(() => props.selectFiltered?.filter((p: { email: string; }) => (p.email !== userInfo.value?.email)).map((p: { email: string; }) => p.email))
</script>

<template>
  <thead>
    <tr class="bg-gray-50">
      <th scope="col" class="relative px-7 sm:w-12 sm:px-6 rounded-t-md">
        <input
          id="check_first" type="checkbox"
          class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
          :checked="indeterminate(users.length) || selectedItems.length === users.length"
          :indeterminate="indeterminate(users.length)"
          @change="selectAll($event, all)"
        />
      </th>
      <th scope="col" class="1xl:w-2/4 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900" @click="orderBy('email')">
        <div class="flex items-center cursor-pointer group">
          Name
          <span
            class="ml-2 flex-none rounded"
            :class="[
              typeOrder === 'email' ?
                'bg-gray-100 text-gray-900 hover:bg-gray-200 p-0.5' :
                'invisible text-gray-400 group-hover:visible group-focus:visible'
            ]">
            <ChevronDownIcon v-if="asc" class="h-4 w-4" aria-hidden="true" />
            <ChevronUpIcon v-else class="h-4 w-4 " />
          </span>
        </div>
      </th>
      <th scope="col" class="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900" @click="orderBy('role')">
        <div
          class="flex relative items-center w-max cursor-pointer group">
          Role
          <div class="group flex">
            <img :src="help" alt="help" class="w-4 h-4 ml-2 text-gray-400" />
            <span class="drop-shadow-tool bg-secondary-500 text-white font-medium text-xs w-73 invisible transition-opacity duration-500 ease-in-out opacity-0 group-hover:opacity-100 group-hover:visible absolute px-3 py-2 -right-0.5 -top-8 rounded-lg">
              Roles define the data each user has access to
            </span>
          </div>
          <span
            class="ml-2 flex-none rounded"
            :class="[
              typeOrder === 'role' ?
                'bg-gray-100 text-gray-900 hover:bg-gray-200 p-0.5' :
                'invisible text-gray-400 group-hover:visible group-focus:visible'
            ]">
            <ChevronDownIcon v-if="asc" class="h-4 w-4" aria-hidden="true" />
            <ChevronUpIcon v-else class="h-4 w-4 " />
          </span>
        </div>
      </th>
      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" @click="orderBy('access-to')">
        <div class="flex items-center cursor-pointer group">
          Access to
          <span
            class="ml-2 flex-none rounded"
            :class="[
              typeOrder === 'access-to' ?
                'bg-gray-100 text-gray-900 hover:bg-gray-200 p-0.5' :
                'invisible text-gray-400 group-hover:visible group-focus:visible'
            ]">
            <ChevronDownIcon v-if="asc" class="h-4 w-4" aria-hidden="true" />
            <ChevronUpIcon v-else class="h-4 w-4 " />
          </span>
        </div>
      </th>
      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" @click="orderBy('tracking')">
        <div
          class="flex items-center relative group w-max cursor-pointer">
          Tracking
          <div class="group flex">
            <img :src="help" alt="help" class="w-4 h-4 ml-2 text-gray-400" />
            <span class="drop-shadow-tool bg-secondary-500 text-white font-medium text-xs w-94 invisible transition-opacity duration-500 ease-in-out opacity-0 group-hover:opacity-100 group-hover:visible absolute px-3 py-2 -right-0.5 -top-8 rounded-lg">
              Shows if you’re collecting email statistics from their mailbox
            </span>
          </div>
          <span
            class="ml-2 flex-none rounded"
            :class="[
              typeOrder === 'tracking' ?
                'bg-gray-100 text-gray-900 hover:bg-gray-200 p-0.5' :
                'invisible text-gray-400 group-hover:visible group-focus:visible'
            ]">
            <ChevronDownIcon v-if="asc" class="h-4 w-4" aria-hidden="true" />
            <ChevronUpIcon v-else class="h-4 w-4 " />
          </span>
        </div>
      </th>
      <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3 rounded-r-md">
        <span class="sr-only">Edit</span>
      </th>
    </tr>
  </thead>
</template>

<style scoped></style>
